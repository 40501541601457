<template>
  <div class="allcontens">
    <div class="content">
      <van-form @submit="onSubmit" @failed="onFailed" class="form">
        <van-field v-model="certificateName" name="certificateName" label="姓名" placeholder="请输入姓名" input-align="left" :rules="[{ required: true}]" />
        <van-field placeholder="请填写手机号" v-model="mobile" input-align="left" name="mobile" type="tel" label="手机号码" :rules="[{ required: true, pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号格式错误' }]" />
        <van-field v-model="certificateNo" name="certificateNo" label="身份证号码" placeholder="请输入身份证号码" input-align="left" :rules="[{ required: true}]" />
        <div class="idCarUpload">
          <div class="positive">
            <c-uploader :uploadIconUrl="idFrontPic" uploadText="上传身份证正面" @upload="uploadImg('front', $event)">
            </c-uploader>
          </div>
          <div class="theOtherSide">
            <c-uploader :uploadIconUrl="idReversePic" uploadText="上传身份证反面" @upload="uploadImg('reverseSide', $event)"></c-uploader>
          </div>
          <div class="error" v-if="errorMsg">
            {{ errorMsg }}
          </div>
        </div>
        <hr>
        <p class="tips">提示：请上传清晰的身份证正反面，以png, jpeg, jpg格式，单张图片大小不超过6M。</p>
        <div class="row justify-center">
          <van-button round block type="info" class="next-btn" size="small" native-type="submit" :loading="loading">下一步1/4</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
  import cUploader from '@/components/cuploader';
  import idFrontPic from '@/assets/uploadimg/shenfenzhengzhengmian.svg'
  import idReversePic from '@/assets/uploadimg/shenfenzhengbeimian.svg'
  import { save, queryStepInform } from '@/api/sign'
  import storage from '@/utils/storage'
  export default {
    name: 'auth',
    components: { cUploader },
    data() {
      return {
        certificateName: '',
        mobile: '',
        certificateNo: '',
        idFrontUrl: '',
        idReverseUrl: '',
        idFrontPic: idFrontPic,
        idReversePic: idReversePic,
        errorMsg: '',
        insuranceNo: '',
        loading: false
      }
    },
    async created() {
      this.insuranceNo = this.$route.query.id || this.$route.query.insuranceNo
      storage.setItem('insuranceNo', this.insuranceNo)
      const res = await queryStepInform({ insuranceNo: this.insuranceNo })
      if (res.success) {
        this.certificateNo = res.content.certificateNo
        this.certificateName = res.content.certificateName
        this.mobile = res.content.mobile
        storage.setItem('bodyNo', res.content.bodyNo)
        if (res.content.idFrontPic) {
          idFrontPic = res.content.idFrontPic
        }
        if (res.content.idReversePic) {
          idReversePic = res.content.idReversePic
        }
        const steps = res.content.steps
        let tips = ''
        let path = ''
        if (steps && steps === 3) {
          if ( this.mobile && this.mobile.length > 0 ) {
            tips = '身份证已经绑定，将进入绑卡操作！'
            path = '/sign/bank_cardInformation'
          } else {
            tips = '您已解绑手机号，将进行手机号重绑！'
            path = '/sign/update_mobile'
          }
        } else if (steps && steps === 4) {
          tips = '已经完成代扣签约，将前往合同签署！'
          path = '/sign/account'
        }
        if (steps && (steps === 3 || steps === 4 )) {
          this.$toast(tips)
          setTimeout(() => {
            this.$router.push({ path: `${path}`, query: { mobile: this.mobile, insuranceNo: this.insuranceNo } })
          }, 2000);
        }
      } else {
        this.$toast(res.respMag)
      }
    },
    methods: {
      async onSubmit(values) {
        this.loading = true
        if (!this.idFrontUrl || !this.idReverseUrl) {
          this.errorMsg = '请上传身份证正、反面图片'
          this.loading = false
          return
        }
        const formFiles = new FormData()
        //  姓名
        formFiles.append('certificateName', values.certificateName);
        // 身份证号码
        formFiles.append('certificateNo', values.certificateNo.toUpperCase());
        // 电话号码
        formFiles.append('mobile', values.mobile);
        // 保单号
        formFiles.append('insuranceNo', this.insuranceNo);
        // 身份证
        formFiles.append('idFrontUrl', this.idFrontUrl)
        formFiles.append('idReverseUrl', this.idReverseUrl)
        try {
          const res = await save(formFiles)
          this.loading = false
          if (!res.success) {
            this.$toast(res.respMag)
            return
          }
          storage.setItem('bodyNo', res.content.bodyNo)
          const queryParam = {
            mobile: values.mobile
          }
          this.$router.push({ path: '/sign/sms_verification', query: queryParam })
        } catch(e) {
          this.loading = false
          this.$toast('提交实名信息异常，请联系客服')
        }
      },
      onFailed() {
        this.$toast('提交信息异常')
      },
      uploadImg(flat, uploadContent) {
        if (flat === 'front') {
          this.idFrontUrl = uploadContent
        } else {
          this.idReverseUrl = uploadContent
        }
      }
    }
  }
</script>
<style scoped>
  .sp {
    font-size: 0.28rem;
    display: inline-block;
    width: 1.4rem;
  }

  .borderbt {
    border-bottom: 1px solid #e4e7ed;
    padding: 0.1rem 0.2rem 0.1rem 0.2rem;
    font-size: 0.28rem;
  }

  .upload-imagef,
  .upload-imagez {
    border: 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 0.4rem;
    box-sizing: border-box;
    width: 3.5rem;
  }

  .indexinput {
    border: 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 0.4rem;
    box-sizing: border-box;
    width: 4rem;
    color: #666;
    /* font-size: 0.3rem; */
  }

  .idCarUpload {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.6rem;
  }

  .imgsize {
    width: 2rem;
    height: 1.36rem;
  }

  .upload-imagef,
  .upload-imagez {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .theOtherSide,
  .positive {
    position: relative;
    width: 250px;
    height: 160px;
    border: 1px dashed #9b9fa5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-bottom: 30px;
  }

  .upsp {
    font-size: 0.25rem;
    margin-top: 0.15rem;
    color: #666;
    font-weight: 600;
  }

  .echo-imgZ,
  .echo-imgF {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .noneborder {
    border-bottom: none;
  }

  .next {
    width: 4rem;
    height: 0.8rem;
    font-size: 0.3rem;
    letter-spacing: 0.01rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.6rem;
    background: #0084FE;
  }

  .tips {
    color: #999;
    padding: 0.3rem;
    font-size: 0.28rem;
  }

  .error {
    color: red;
    padding-top: 3px;
    padding-bottom: 20px;
    font-size: 14px;
  }
</style>
